// customerService.js
'use strict';
import loadIntercomScript from '../resource/loadIntercomScript.js';
import { defaultIntercomSetting } from '../resource/intercomScript.js';
import { loadZendeskScript } from '../resource/zendesk.js';
import { getIsInBrowserMainThread } from '../resource/getJsEnvironment.js';

export const ProviderName = {
  INTERCOM: 'intercom',
  ZENDESK: 'zendesk',
};

const isInBrowserMainThread = getIsInBrowserMainThread();
const zendeskFieldMap = {
  user_avatar_url: '11151031063695',
  user_display_name: '11151001099919',
  user_id: '11150854603407',
  username: '11151030680719',
  client_id: '11151366477583',
  webapp_version: '11151359620111',
  webapp_is_pwa: '11151372457999',
  sentry_event_url: '11151035830927',
  sentry_event_id: '11151043991823',
};
let isMessengerOpened = false;

const strategies = {
  [ProviderName.INTERCOM]: {
    init: loadIntercomScript,
    run: (...args) => {
      switch (args[0]) {
        case 'loginUser': {
          const { intercomUserHash, userId, avatar } = args[1];
          window.Intercom('update', {
            user_hash: intercomUserHash,
            user_id: userId,
            avatar,
          });
          break;
        }
        case 'logoutUser': {
          window.Intercom('shutdown');
          window.Intercom('boot', defaultIntercomSetting);
          break;
        }
        case 'update': {
          window.Intercom(args[0], args[1]);
          args[2]?.(); // to mimic zendesk's 'conversationFields' callback
          break;
        }
        default:
          window.Intercom(...args);
          break;
      }
    },
    trackPageView: () => window.Intercom?.('update'),
  },
  [ProviderName.ZENDESK]: {
    init: async ({ zendeskKey }) => {
      await loadZendeskScript({ zendeskKey });
      window.zE('messenger', 'close');
      window.zE('messenger:on', 'close', () => {
        isMessengerOpened = false;
      });
      window.zE('messenger:on', 'open', () => {
        isMessengerOpened = true;
      });
    },
    run: (...args) => {
      switch (args[0]) {
        case 'update': {
          // map object style data for intercom into array style for zendesk
          const fields =
            (args[1] &&
              Object.keys(args[1]).reduce((acc, key) => {
                return [
                  ...acc,
                  { id: zendeskFieldMap[key] || key, value: args[1][key] },
                ];
              }, [])) ||
            [];
          window.zE('messenger:set', 'conversationFields', fields, args[2]);
          break;
        }
        case 'onUnreadCountChange': {
          window.zE('messenger:on', 'unreadMessages', args[1]);
          break;
        }
        case 'loginUser': {
          const { zendeskUserJwt, userId, username, userDisplayName, avatar } =
            args[1];
          // 1. There is a chance that `connectCustomerServiceWithUserId()` would be dispatched after the web widget is launched.
          //    So calling `close` here would literally close the opened widget (either before or after logged in).
          // 2. Since we only update information here, we don't necessarily need to close it first.
          window.zE('messenger', 'loginUser', function (callback) {
            callback(zendeskUserJwt);
          });
          window.zE('messenger:set', 'conversationFields', [
            { id: zendeskFieldMap['user_id'], value: userId },
            { id: zendeskFieldMap['username'], value: username },
            {
              id: zendeskFieldMap['user_display_name'],
              value: userDisplayName,
            },
            {
              id: zendeskFieldMap['user_avatar_url'],
              value: avatar?.image_url,
            },
          ]);
          break;
        }
        case 'logoutUser': {
          window.zE('messenger', 'close');
          window.zE('messenger', 'logoutUser');
          break;
        }
        case 'toggleOpen': {
          if (isMessengerOpened) {
            window.zE('messenger', 'close');
          } else {
            window.zE('messenger', 'open');
          }
          break;
        }
        case 'close': {
          window.zE('messenger', 'close');
          break;
        }
        case 'open':
        case 'showNewMessage':
        case 'showMessages':
        case 'show': {
          window.zE('messenger', 'open');
          break;
        }
        case 'trackEvent':
        default:
          break;
      }
    },
  },
};

let initializedStrategyName = '';
let isInitialized = false;
/**
 * initialize customer service sdk (intercom/zendesk)
 * @param {ProviderName} {[providerName=ProviderName.ZENDESK]} - choose to init sdk of which provider
 * @param {object} {args} - args to init sdk, mostly will be sdk key
 */
export const init = async ({ providerName = ProviderName.ZENDESK, args }) => {
  if (!isInBrowserMainThread) {
    return;
  }
  if (!initializedStrategyName) {
    const strategy = strategies[providerName];
    if (strategy) {
      initializedStrategyName = providerName;
      await strategy.init(args);
      flushExecQueue();
      isInitialized = true;
    }
  }
};

/**
 * Push api call of customer service sdks.
 * The pushed calls will be execute right away if the sdk initiallied, otherwise it will just queued up
 * @param {array} {args} - array of arguments used to call sdks
 * @param {bool} {shouldPersist} - whether the pushed api call should be recall after 'logoutUser'
 */
export const push = ({ args, shouldPersist }) => {
  if (!isInBrowserMainThread) {
    return;
  }

  if (shouldPersist) {
    // persist args to recall after logout, ex: setup client id
    window._customerServiceExecutePersistQueue =
      window._customerServiceExecutePersistQueue || [];
    window._customerServiceExecutePersistQueue.push(args);
  }

  if (!isInitialized) {
    // queue args befor strategy inited
    window._customerServiceExecuteQueue =
      window._customerServiceExecuteQueue || [];
    window._customerServiceExecuteQueue.push(args);
  } else if (window._customerServiceExecuteQueue?.length) {
    flushExecQueue();
    run(...args);
  } else {
    run(...args);
  }
};

const flushExecQueue = () => {
  const queue = window._customerServiceExecuteQueue;
  window._customerServiceExecuteQueue = [];
  queue.forEach(item => {
    run(...item);
  });
};

const run = (...args) => {
  const strategy = strategies[initializedStrategyName];
  strategy?.run(...args);

  // if incoming command is 'logout', we restore persisted run back
  if (args[0] === 'logoutUser') {
    window._customerServiceExecutePersistQueue.forEach(item => {
      strategy?.run(...item);
    });
  }
};

/**
 * Send page view track event to customer service sdk.
 * Currently only intercom support it.
 */
export const trackPageView = () => {
  if (!isInBrowserMainThread) {
    return;
  }
  strategies[initializedStrategyName]?.trackPageView?.();
};
